// 页面埋点相关信息
export const trackingInfo = {
  // Quotation Form Page 下单询价页面
  QuotationPage: {
    page: {
      pageId: '11100',
      pageName: 'Get Quotation Form'
    },

    // 当前页面的Dom信息
    elements: {
      11100001: {
        elementId: '11100001',
        elementName: 'Next',
        elementContent: 'Next',
        elementType: 'Button',
        elementPosition: '1'
      }
    }
  },

  THQuotation: {
    page: {
      pageId: '11110',
      pageName: 'Get Quotation'
    },

    // 当前页面的Dom信息
    elements: {
      11110001: {
        elementId: '11110001',
        elementName: 'Next',
        elementContent: 'Next',
        elementType: 'Button',
        elementPosition: '1'
      }
    }
  },
  ThaiPricingList: {
    page: {
      pageId: '11120',
      pageName: 'Select Plan'
    },

    // 当前页面的Dom信息
    elements: {
      11120001: {
        elementId: '11120001',
        elementName: 'SelectPlan',
        elementContent: 'SelectPlan',
        elementType: 'Button',
        elementPosition: '1'
      },
      11120002: {
        elementId: '11120002',
        elementName: 'ViewDetail',
        elementContent: 'ViewDetail',
        elementType: 'Button',
        elementPosition: '1'
      }
    }
  },
  THViewDetails: {
    page: {
      pageId: '11130',
      pageName: 'View Details'
    }
  },
  THPlanCompare: {
    page: {
      pageId: '11140',
      pageName: 'Plan Compare'
    }
  },
  THPlanCustomization: {
    page: {
      pageId: '11150',
      pageName: 'Plan Customization'
    },

    // 当前页面的Dom信息
    elements: {
      11150001: {
        elementId: '11150001',
        elementName: 'SelectPlan',
        elementContent: 'SelectPlan',
        elementType: 'Button',
        elementPosition: '1'
      }
    }
  },
  THApplicationForm: {
    page: {
      pageId: '11160',
      pageName: 'Application Form'
    },

    // 当前页面的Dom信息
    elements: {
      11160001: {
        elementId: '11160001',
        elementName: 'Next',
        elementContent: 'Next',
        elementType: 'Button',
        elementPosition: '1'
      }
    }
  },
  THOrderDetails: {
    page: {
      pageId: '11170',
      pageName: 'Order Details'
    },

    // 当前页面的Dom信息
    elements: {
      11170001: {
        elementId: '11170001',
        elementName: 'Pay',
        elementContent: 'Pay',
        elementType: 'Button',
        elementPosition: '1'
      }
    }
  },

  // Pricing Options Page 询价选择产品列表页面
  PricingOptionsPage: {
    page: {
      pageId: '11200',
      pageName: 'Pricing Options'
    },

    // 当前页面的Dom信息
    elements: {
      11200101: {
        elementId: '11200101',
        elementName: 'Product Pricing List',
        elementContent: '',
        elementType: 'List',
        elementPosition: '1'
      },
      11200102: {
        elementId: '11200102',
        elementName: 'Express',
        elementContent: 'Express',
        elementType: 'Express Button',
        elementPosition: '2'
      }
    }
  },

  // Pricing Details Page 选择单个产品后的详情页面
  PricingDetailPage: {
    page: {
      pageId: '11300',
      pageName: 'Pricing Detail'
    },

    // 当前页面的Dom信息
    elements: {
      11300001: {
        elementId: '11300001',
        elementName: 'Product Information',
        elementContent: '',
        elementType: 'Icon',
        elementPosition: '1'
      },
      11300002: {
        elementId: '11300002',
        elementName: 'Save Quotation',
        elementContent: '',
        elementType: 'Icon',
        elementPosition: '2'
      },
      11300003: {
        elementId: '11300003',
        elementName: 'Collapse',
        elementContent: '',
        elementType: 'Switch',
        elementPosition: '3'
      },
      11300004: {
        elementId: '11300004',
        elementName: '${coverageName}',
        elementContent: '',
        elementType: 'Checkbox',
        elementPosition: '4'
      },
      11300005: {
        elementId: '11300005',
        elementName: 'Next',
        elementContent: 'Next',
        elementType: 'Button',
        elementPosition: '5'
      }
    }
  },

  // Get Application Form 投保表单页面
  ApplicationPage: {
    page: {
      pageId: '11400',
      pageName: 'Get Application Form'
    },

    // 当前页面的Dom信息
    elements: {
      11400001: {
        elementId: '11400001',
        elementName: 'Product Information',
        elementContent: '',
        elementType: 'Icon',
        elementPosition: '1'
      },
      11400002: {
        elementId: '11400002',
        elementName: 'Collapse',
        elementContent: '',
        elementType: 'Switch',
        elementPosition: '2'
      },
      11400003: {
        elementId: '11400003',
        elementName: 'Pay',
        elementContent: 'Pay',
        elementType: 'Button',
        elementPosition: '3'
      }
    }
  },

  // 询价单详情
  RfqDetails: {
    page: {
      pageId: '12200',
      pageName: 'General Information'
    },

    // 当前页面的Dom信息
    elements: {
      12200006: {
        elementId: '12200006',
        elementName: 'Information Detail',
        elementContent: '',
        elementType: 'List',
        elementPosition: '6'
      },
      12200007: {
        elementId: '12200007',
        elementName: 'Quotation Detail',
        elementContent: '',
        elementType: 'Link',
        elementPosition: '7'
      },
      12200008: {
        elementId: '12200008',
        elementName: 'Decline',
        elementContent: 'Decline',
        elementType: 'Button',
        elementPosition: '8'
      },
      12200009: {
        elementId: '12200009',
        elementName: 'Proceed',
        elementContent: 'Proceed',
        elementType: 'Button',
        elementPosition: '9'
      }
    }
  },

  // 订单详情
  OrderDetailsVN: {
    page: {
      pageId: '12200',
      pageName: 'Order Detail'
    },

    // 当前页面的Dom信息
    elements: {
      12200010: {
        elementId: '12200010',
        elementName: 'Save Order Number',
        elementContent: '',
        elementType: 'Icon',
        elementPosition: '10'
      },
      12200011: {
        elementId: '12200011',
        elementName: 'Benefit & Claim Detail',
        elementContent: '',
        elementType: 'Link',
        elementPosition: '11'
      }
    }
  },

  // 保单详情
  PolicyDetailsVN: {
    page: {
      pageId: '12200',
      pageName: 'Policy Detail'
    },

    // 当前页面的Dom信息
    elements: {
      12200013: {
        elementId: '12200013',
        elementName: 'See Detail',
        elementContent: '',
        elementType: 'Link',
        elementPosition: '13'
      }
    }
  }
}


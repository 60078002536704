import i18n from '@/i18n/i18n'
import { getTenantRegion } from '@fu/core'

export const getPolicyDetailsColumns = (lang) => {
  return [
    { label: i18n.t('productName'), prop: lang == 'en' ? 'productName' : 'productNameLocal' },
    { label: i18n.t('payment.benefitPack'), prop: lang == 'en' ? 'benefitPackage' : 'benefitPackageLocal' },
    { label: i18n.t('payment.policyHolder'), prop: 'policyHolderName',
      display: (scope) => {
        if (getTenantRegion() === 'ID') { return false } // Hide if region is ID
      }
    },
    { label: i18n.t('payment.premiumBenificiary'), prop: lang == 'en' ? 'premiumBeneficiary' : 'premiumBeneficiaryLocal' }
  ]
}

export const getExpiryDetailsColumns = (lang) => {
  return [
    { label: i18n.t('productName'), prop: lang == 'en' ? 'productName' : 'productNameLocal' },
    { label: i18n.t('payment.benefitPack'), prop: lang == 'en' ? 'benefitPackage' : 'benefitPackageLocal' }
  ]
}

export const paymentDetailsColumns = (lang) => {
  return [
    { label: i18n.t('paymentDue'), prop: 'paymentDueDate', formater: 'date' }
  ]
}

// CS-4562 印尼 Live Photo 新增
export const getVehicleDetailsColumns = (lang) => {
  return [
    { label: i18n.t('vehicleInfo.carType'), prop: 'vehicleSeries' },
    { label: i18n.t('vehicleInfo.platNo'), prop: 'plateNo' },
    { label: i18n.t('vehicleInfo.engineNo'), prop: 'vehicleEngineNo' }
  ]
}

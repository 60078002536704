import { dispatchAction } from '@/utils/dispatch'
import { trackingInfo } from './config/index'

export const tracking = (paths = [], type, params = {}) => {
  // 没有传埋点类型并且参数路径少于2的时候则不符合逻辑
  if (!type && paths.length < 2) { return }

  const [pageName, elementId] = paths
  const pageInfo = trackingInfo[pageName]

  // 没有匹配到配置的页面也终止
  if (!pageInfo) { return }
  // 传过来的参数路径超过2个的时候 那肯定是 appOnClick 方式的埋点
  const finalType = paths.length >= 2 ? 'appOnClick' : type
  const pageParams = pageInfo.page
  const elementsInfo = pageInfo.elements || {}
  const elementsParams = elementsInfo[elementId] || {}

  switch (finalType) {
    case 'appViewScreen':
      dispatchAction({
        method: 'appViewScreen',
        params: {
          pageId: '',
          pageName: '',
          pageContent: '',

          ...pageParams,
          ...params
        }
      })
      break
    case 'appLeavePage':
      dispatchAction({
        method: 'appLeavePage',
        params: {
          pageId: '',
          pageName: '',
          pageContent: '',
          times: '',

          ...pageParams,
          ...params
        }
      })
      break
    case 'appOnClick':
      dispatchAction({
        method: 'appOnClick',
        params: {
          pageId: '',
          pageName: '',
          elementId: '',
          elementName: '',
          elementContent: '',
          elementType: '',
          elementPosition: '',

          ...pageParams,
          ...elementsParams,
          ...params
        }
      })
      break
  }
}

<template>
  <Cell
    class="fu-cell"
    :title="label"
    :label="desc"
    :value="getValue()"
    :class="{'strong-cell': attrs.isLink, 'strong-value': valueBold, 'dander': dander}"
    :value-class="valclass + getColorClass()"
    :label-class="labelclass + getColorClass()"
    v-bind="finalAttrs"
    @click="onClick"
  >
    <template v-if="copy" slot="title">
      <span>{{ label }}</span>
      <FuCopy :value="getValue()" :tracking-path="trackingPath" />
    </template>

    <template v-for="(slot, slotName) in $slots" #[slotName]="slotProps">
      <slot :name="slotName" v-bind="slotProps" />
    </template>
  </Cell>
</template>

<script>
import { Cell } from 'vant'
import FuCopy from '../FuCopy'
import { formatDateId, toThousandFilter } from '@/filters'
import store from '@/store'

export default {
  name: 'FuCell',
  components: { Cell, FuCopy },
  props: {
    value: { type: [String, Number], default: '' },
    label: { type: String, default: '' },
    desc: { type: [String, Object], default: null },
    copy: { type: Boolean, default: false },
    valueBold: { type: Boolean, default: false },
    dander: { type: Boolean, default: false },
    // 是否需要双语切换
    bilingual: { type: Boolean, default: false },
    attrs: { type: Object, default: () => { return {} } },
    datas: { type: Object, default: () => { return {} } },
    // 埋点路径
    trackingPath: { type: Array, default: () => { return [] } },
    valclass: {
      type: [String, Function],
      default: '' // Provide a default value if needed
    },
    labelclass: { type: String, default: '' }
  },
  data() {
    return {}
  },
  computed: {
    finalAttrs() {
      return {
        border: false,
        ...this.attrs
      }
    }
  },
  methods: {
    getValue() {
      const { formater, prop } = this.$attrs
      const formaterType = typeof formater
      const NULL = ''

      const value = prop ? this.datas[prop] : NULL

      if (!formater && !prop) { return '' }

      // 没有格式化要求则直接返回对象取值
      if (!formater) {
        if (this.bilingual) {
          const key = store.state.base.lang == 'en' ? prop : `${prop}Local`

          return this.datas[key] || NULL
        }

        return value || NULL
      }
      // 根据不同的格式化配置 获取对应的值
      if (formaterType == 'function') { // 自定义方法
        return formater(this.datas)
      } else if (formaterType == 'object') { // 码表
        return formater[value] || NULL
      } else if (formaterType == 'string') { // 内置过滤器
        const value = this.datas[prop]
        if (formater == 'date') {
          return formatDateId(value, 'DD/MM/YYYY')
        }
        if (formater == 'datetime') {
          return formatDateId(value, 'DD/MM/YYYY HH:mm:ss')
        }
        if (formater == 'currency') {
          return `${toThousandFilter(value)} ${this.$store.state.base.currency}`
        }
      }

      return value || NULL
    },
    getColorClass() {
      const { colorformater, prop } = this.$attrs
      const colorformatertype = typeof colorformater
      if (colorformater !== undefined) {
        const NULL = ''
        const value = prop ? this.datas[prop] : NULL
        if (!colorformater && !prop) { return '' }

        if (colorformatertype == 'object') { // 码表
          return colorformater[value] || NULL
        }
      }
    },
    onClick() {
      const { onClick } = this.$attrs

      if (onClick && typeof onClick == 'function') {
        onClick(this.datas)
      }
    }
  }
}
</script>

<style lang="scss">
.fu-cell {
  padding: 4px 0;
  font-family: 'Roboto';
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  min-height: 16px;
  color: #4B4B4B;
  background-color: #f2f2f2;

  &.strong-cell {
    .van-cell__title {
      font-weight: 900;
    }

    .van-cell__right-icon {
      text-align: right;
      color: #C82A21;
    }
  }

  .van-cell__title {
    flex: none;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
  }

  .van-cell__value {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: normal;
    color: #4B4B4B;
    font-size: 12px;
    padding-right: 0px;
  }

  &.strong-value {
    .van-cell__value {
      font-weight: 900;
    }
  }

  &.dander {
    .van-cell__left-icon,
    .van-cell__title,
    .van-cell__value {
      color: #C82A21;
    }
  }
}
</style>

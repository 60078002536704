<template>
  <CellGroup class="fu-cell-group" :border="border">
    <slot name="top" />

    <p
      v-if="title"
      class="fu-cell-group-tit"
      @click="onClickTitle"
    >
      <span>{{ title }}</span>

      <Tag v-if="tag" v-bind="tagProps">{{ tag }}</Tag>

      <Icon v-if="titleLink" name="arrow" />
    </p>

    <!-- 骨架屏 -->
    <Skeleton
      v-if="loading"
      :row="finalColumns.length || skeleton"
      style="margin-top: 12px"
    />

    <!-- 显示内容 -->
    <template v-if="finalColumns.length && !loading">
      <FuCell
        v-for="cell in finalColumns"
        :key="cell.label || cell.prop"
        :datas="datas"
        :value-bold="true"
        :val-class="cell.valclass"
        v-bind="cell"
      />
    </template>

    <slot />
  </CellGroup>
</template>

<script>
import { CellGroup, Tag, Skeleton, Icon } from 'vant'
import FuCell from '../FuCell'

export default {
  name: 'FuCellGroup',
  components: { CellGroup, Tag, Skeleton, FuCell, Icon },
  props: {
    loading: { type: Boolean, default: false },
    skeleton: { type: Number, default: 0 },
    positonBottom: { type: Boolean, default: false },

    title: { type: String, default: '' },
    titleLink: { type: String, default: '' },
    border: { type: Boolean, default: false },

    tag: { type: String, default: '' },
    tagAttrs: { type: Object, default: () => { return {} } },

    columns: { type: Array, default: () => { return [] } },
    datas: { type: Object, default: () => { return {} } }
  },
  data() {
    return {}
  },
  computed: {
    tagProps() {
      return {
        size: 'medium',

        ...this.tagAttrs
      }
    },
    finalColumns() {
      return this.columns.filter(item => {
        return !item.display || !!item.display(this.datas)
      })
    }
  },
  methods: {
    onClickTitle() {
      if (!this.titleLink) { return }

      this.$router.push(this.titleLink)
    }
  }
}
</script>

<style lang="scss">
.fu-cell-group + .fu-cell-group {
  margin-top: 10px;
}
.fu-cell-group {
  position: relative;
  z-index: 1;
  padding: 0 12px;
  // padding-bottom: 10px;
  background-color: #f2f2f2;
  box-shadow: 0px .4px 1.6px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  // min-height: 50px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;

  .van-skeleton {
    padding: 0;
  }

  &.fu-link-group {
    margin: auto;
    margin-bottom: 16px;
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    background: #fff;

    .fu-cell-group-tit {
      padding: 0;
    }
  }
}
.fu-cell-group-tit {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  padding-bottom: 3px;

  font-family: "Roboto";
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  // color: #C82A21;
  overflow: hidden;

  .van-icon {
    color: #C82A21;
    font-size: 18px;
    font-weight: 500;
  }

  span {
    font-family: "Roboto";
  }

  .van-tag {
    border-radius: 6px;
    font-size: 10px;
  }
}
</style>

<template>
  <span class="fu-copy" @click="onCopy()" />
</template>

<script>
import { Toast } from 'vant'
import { tracking } from '@/tracking'

export default {
  name: 'FuCopy',
  components: { },
  props: {
    value: { type: String, default: '' },
    // 埋点路径
    trackingPath: { type: Array, default: () => { return [] } }
  },
  data() {
    return {}
  },
  methods: {
    onCopy() {
      if (!this.value) return
      // 埋点
      tracking(this.trackingPath)

      const input = document.createElement('input')
      input.value = this.value
      input.readOnly = true
      document.body.appendChild(input)
      input.select()
      input.setSelectionRange(0, input.value.length)
      document.execCommand('Copy')
      document.body.removeChild(input)
      window.scrollTo(0, 0)
      Toast.success(this.$t('policy.details.copyToast'))
    }
  }
}
</script>

<style lang="scss" scoped>
.fu-copy {
  position: relative;
  margin-left: 10px;
  width: 28px;
  height: 28px;
  display: inline-block;
  vertical-align: middle;

  // background-color: #FFFFFF;
  // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 99px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 10px;
    border: 2px solid #000;
    border-radius: 2px;
    background-color: #F2F2F2;
  }

  &::before {
    right: 7px;
    bottom: 5px;
    z-index: 1;
  }

  &::after {
    left: 6px;
    top: 6px;
    // z-index: 2;
  }
}

</style>
